.pricing-section {
  padding: 4rem 1rem;
  display: flex;
  justify-content: center;
}

.pricing-container {
  /* max-width: 900px; */
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.pricing-card {
  background: #dceaf9;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 2rem;
  width: 100%;
  max-width: 900px;
}

.pricing-title {
  font-size: 2rem;
  font-weight: bold;
  color: black;
  margin-bottom: 1rem;
  text-align: center;
}

.pricing-text {
  font-size: 1.3rem;
  color: black;
  margin-bottom: 1.5rem;
  text-align: center;
}

.pricing-button {
  padding: 0.75rem 1.5rem;
  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(135deg, #00c6ff, #0072ff) border-box;
  color: rgb(22,106,234);
  padding: 8px 15px;
  font-size: 16px;
  border: 2px solid transparent;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin:0 auto ;
  display: block;
}

.pricing-button:hover {
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: #ffffff;
  border-color: rgb(22,106,234);
  transform: translateY(-2px);
}



.voice-recorder {
  /* margin-top: 5%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: white;
  /* min-height: 100vh; */
  position: relative;
  overflow: hidden;
  width: calc(100% - 200px);
  min-height: calc(100vh - 96px);
  left: 200px;
  transition: width 0.3s ease, left 0.3s ease;
}

.sidebar-closed.voice-recorder {
  width: calc(100% - 60px);
  left: 60px;
  min-height: calc(100vh - 96px);
}

/* Audio wave animation container */
.audio-wave {
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease;
}

/* Audio container styling */
.audio-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 10px 0;
}

/* Mic button styling */
.mic-button {
  background: linear-gradient(135deg, #1c7ed6, #0072ff);
  color: #ffffff;
  border: none;
  border-radius: 50%;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-right: 10px;
}

.mic-button.recording {
  background: linear-gradient(135deg, #ff0000, #ff6347);
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* Audio player styling */
.audio-player {
  flex: 1;
}

/* Query display box styling */
.query-display {
  margin: 10px 0;
  width: 100%; /* Full width by default */
  max-width: 600px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

/* Header for both query and analysis */
.query-header {
  width: 100%;
  padding: 5px;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.analysis-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Voice Text Styling */
.voice-text {
  font-size: 16px;
  color: #222;
  margin: 0;
  padding: 5px;
  padding-top: 0;
  flex-grow: 1;
  padding-right: 30px;
  text-align: left;
}
#edit-text {
  width: 100%;
  outline: none;
}

/* Edit button styling */
.edit-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #ccc;
  transition: color 0.3s ease;
}

.edit-button:hover {
  color: rgb(22, 106, 234);
}

.file-upload-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.upload-button {
  padding: 5px 10px;
  color: rgb(22, 106, 234);
  border: none;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(135deg, #00c6ff, #0072ff) border-box;
  border: 2px solid transparent;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  display: inline-block;
  outline: none;
  /* width: 25%; */
  margin: 0px auto;
  max-width: 400px;
  margin-top: 30px;
  text-align: center;
}

.upload-button:hover {
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: #ffffff;
  border-color: rgb(22, 106, 234);
}

.icon-container {
  display: flex;
  gap: 2px;
}
/* Generate Analytics button styling */
.generate-button {
  margin: 20px 0;
  padding: 5px 10px;
  color: rgb(22, 106, 234);
  border: none;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(135deg, #00c6ff, #0072ff) border-box;
  border: 2px solid transparent;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.generate-button:hover {
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: #ffffff;
  border-color: rgb(22, 106, 234);
}

/* Graph display styling */
.graph-display {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Graph container styling */
.graph-container {
  width: 100%;
  max-width: 600px;
  margin: 10px 0;
  padding: 10px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
}

/* Analysis display styling */
.analysis-display {
  margin-top: 10px;
  width: 100%;
  max-width: 600px;
  background: #ffffff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.query-display h3 {
  font-size: 18px;
  margin: 0;
  color: #ccc;
}

.analysis-display h3 {
  font-size: 18px;
  margin: 0;
  color: #ccc;
}

/* Copy button styling */
.copy-button {
  background: #ffffff;
  color: #ccc;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.copy-button:hover {
  color: rgb(22, 106, 234);
}

.analysis-display p {
  font-size: 16px;
  color: #222;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .voice-recorder {
    margin-top: 9.5%;
  }
  .sidebar-closed.voice-recorder {
    width: 100%;
    left: 0;
  }
  .query-display,
  .analysis-display,
  .graph-container {
    width: 90%;
    max-width: none;
    margin: 10px auto;
  }

  .mic-button {
    padding: 8px;
    font-size: 14px;
    margin-right: 5px;
  }

  .voice-text {
    font-size: 14px;
    padding-right: 20px;
  }

  .generate-button {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .voice-recorder {
    margin-top: 14%;
  }
  .query-display,
  .analysis-display,
  .graph-container {
    width: 100%;
  }

  .mic-button {
    padding: 6px;
    font-size: 12px;
  }

  .voice-text {
    font-size: 12px;
    padding-right: 10px;
  }

  .generate-button {
    font-size: 12px;
  }
}

/* new csvtabledata */
/* Table Container */
.table-container1 {
  margin: 20px auto;
  max-width: 90%;
  overflow-x: auto;
  scrollbar-width: thin;
  font-family: Arial, sans-serif;
  height: 200px;
}

/* Data Table */
.data-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Table Header */
.table-header {
  background-color: #f4f4f4;
}

.table-header-cell {
  padding: 12px 16px;
  border: 1px solid #ddd;
  text-align: left;
  font-weight: bold;
  color: #333;
}

/* Table Body */
.table-body .table-row:nth-child(odd) {
  background-color: #fafafa;
}

.table-row:hover {
  background-color: #f1f1f1;
}

.table-cell {
  padding: 10px 14px;
  border: 1px solid #ddd;
  text-align: left;
  color: #555;
}

/* No Data Message */
.no-data-message {
  text-align: center;
  color: #666;
  font-size: 16px;
  margin: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .table-header-cell,
  .table-cell {
    font-size: 14px;
    padding: 8px 10px;
  }
}

@media (max-width: 480px) {
  .data-table {
    font-size: 12px;
  }
}

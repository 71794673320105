.navbar {
  background-color: white;
  padding: 6px 10px 6px 10px;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1500;
  box-shadow: 0 4px 15px rgba(48, 48, 48, 0.1);
  width: calc(100% - 200px);
  left: 200px;
  transition: width 0.3s ease, left 0.3s ease;
}
.sidebar-closed.navbar {
  width: calc(100% - 60px);
  left: 60px;
}

.navbar.transparent {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 15px rgba(48, 48, 48, 0.2);
}

.navbar-logo {
  padding-top: 5px;
  height: 45px;
  transition: transform 0.3s ease;
  margin-top: -10px;
}

.navbar-logo:hover {
  transform: scale(1.05);
}

.navbar-toggle {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  display: none;
  transition: transform 0.3s ease;
}

.navbar-demo-link {
  color: rgb(22, 106, 234);
  border: 2px solid transparent;
  border-radius: 10px;
  padding: 8px 15px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(135deg, #00c6ff, #0072ff) border-box;
}

.navbar-demo-link:hover {
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: #ffffff;
  border-color: rgb(22, 106, 234);
  transform: translateY(-2px);
}

.navbar-dropdown {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 10px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 999;
  text-align: center;
}

.navbar-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar-text {
  font-size: 0.9rem;
  color: #333;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  margin-top: -10px;
  margin-bottom: 0px;
}

.add_sentence {
  font-size: 1.5rem;
  text-align: center;
  background: linear-gradient(135deg, #013698, hsl(275, 97%, 36%), #8a2be2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-weight: bold;
}

.cursor {
  display: inline-block;
  width: 2px;
  height: 0.8em;
  background: linear-gradient(135deg, #013698, hsl(275, 97%, 36%), #8a2be2);
  margin-left: 2px;
  animation: blink 0.7s steps(2) infinite;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

/* Media queries for responsiveness */

@media (max-width: 970px) {
  .add_sentence {
    font-size: 1.3rem;
    text-align: center;
    padding: 5px;
  }
  .navbar-text {
    font-size: 0.7px;
  }
}
@media (max-width: 768px) {
  .sidebar-closed.navbar {
    width: 100%;
    left: 0px;
  }
  .navbar-demo-link.desktop-only {
    display: none;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-dropdown {
    width: 100%;
  }
  .navbar-logo {
    height: 45px;
  }
  .add_sentence {
    font-size: 0.9rem;
    text-align: center;
    padding: 5px;
  }
  .navbar-text {
    font-size: 0.7px;
  }
}

@media (max-width: 480px) {
  .navbar-logo {
    height: 40px;
  }

  .navbar-demo-link {
    padding: 5px;
    font-size: 14px;
  }
  .add_sentence {
    font-size: 0.8rem;
  }
  .navbar-text {
    font-size: 0.4px;
  }
}

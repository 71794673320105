.navbar1 {
  background-color: white;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 0px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  z-index: 1000;
}
.navbar1.transparent {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 15px rgba(48, 48, 48, 0.2);
}
.navbar1.hidden {
  transform: translateY(-100%);
  opacity: 0;
}

.navbar1.visible {
  transform: translateY(0);
  opacity: 1;
}

.brand p {
  font-size: 1rem;
  /* font-weight: bold; */
  color: #333;
  margin-top: -15px;
  margin-left: 8px;
}
.navbar-logo-landingPage {
  width: 100%;
  height: 55px;
}
.nav-links1 {
  list-style: none;
  display: flex;
  gap: 24px;
}

.nav-links1 a {
  text-decoration: none;
  font-size: 1rem;
  color: #333;
  transition: color 0.3s ease;
}

.nav-links1 a:hover {
  color: rgb(22, 106, 234);
}

.nav-links1 .get-started a {
  font-weight: bold;
  color: rgb(22, 106, 234);
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar1 {
    padding: 0px 16px;
  }

  /* .brand p { */
    /* font-size: 0.8rem; */
    /* margin-top: -10px; */
  /* } */

  .nav-links1 {
    gap: 16px;
  }
  .nav-links1 a {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .nav-links1 {
    gap: 10px;
  }
  .navbar1 {
    padding: 0px 6px;
  }
  .brand p {
    display: none;
  }
  .navbar-logo-landingPage {
    width: 100%;
    height: 45px;
  }
}
@media (max-width: 350px) {
  .navbar1 {
    padding: 0px 3px 0px 0px;
  }
}
@media (max-width: 320px) {
 
  .navbar-logo-landingPage {
    width: 100%;
    height: 35px;
  }
  .nav-links1 {
    gap: 8px;
  }
 
}

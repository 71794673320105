.table {
  position: relative;
  width: calc(100% - 200px);
  left: 200px;
  transition: width 0.3s ease, left 0.3s ease;
}
.table-container {
  margin: 50px auto;
  width: 70%;
  max-width: 1200px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 10%;
}

.sidebar-closed.table {
  width: calc(100% - 60px);
  left: 0px;
  margin: auto;

  min-height: calc(100vh - 100px);
}
.table-title {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-weight: bold;
  font-size: 25px;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
}

.responsive-table th,
.responsive-table td {
  text-align: left;
  padding: 12px;
  border: 1px solid #ddd;
}

.responsive-table th {
  background-color: rgb(22, 106, 234);
  color: white;
}

.responsive-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.responsive-table tr:hover {
  background-color: #ddd;
}
.Empty-table {
  text-align: center;
}

@media (max-width: 768px) {
  .sidebar-closed.table-container {
    width: 70%;
    left: 0px;
  }
  .responsive-table th,
  .responsive-table td {
    font-size: 14px;
    padding: 10px;
  }
  .table-container {
    width: 90%;
    margin-top: 20%;
  }
}

@media (max-width: 480px) {
  .responsive-table th,
  .responsive-table td {
    font-size: 12px;
    padding: 8px;
  }
  .table-container {
    width: 90%;
    margin-top: 25%;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(22, 106, 234);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}

#loginForm {
  height: 298px;
  margin-top: 35px;
}
#register_form {
  height: 310px;
}
.bg-gray-900 {
  height: 100%;
}

#pipeline-intro {
  background-color: #1c2430;
}
#or {
  margin: -20.4% 0px -7% 0px;
}

.bg-custom-gradient:hover {
  background-image: linear-gradient(135deg, #57a4e7, #4487d7);
}

@media (max-width: 380px) {
  #validation {
    top: -270px;
  }
}

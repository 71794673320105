.modal-overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1600;
  backdrop-filter: blur(5px);
}

.modal-content1 {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  max-width: 90%;
  width: 400px;
  text-align: center;
  position: relative;
  animation: slide-down 0.3s ease-out;
}

@keyframes slide-down {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.modal-content1 h2 {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: #333;
  font-weight: bold;
}

.modal-content1 .close-button {
  position: absolute;
  top: -0.7rem;
  left: 10.5rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease;
}



.modal-content1 button {
  display: block;
  width: 100%;
  padding: 0.8rem;
  margin: 0.5rem 0;
  font-size: 1rem;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.modal-content1 button:hover {
  transform: translateY(-2px);
}

.modal-content1 button:focus {
  outline: none;
}

.modal-content1 button span {
  margin-right: 0.5rem;
}

.modal-content1 button:last-child {
  background-color: #6c757d;
}

.modal-content1 button:last-child:hover {
  background-color: #5a6268;
}

.modal-content1 button[disabled] {
  background-color: #d3d3d3; 
  color: #8a8a8a; 
  cursor: not-allowed; 
}

.modal-content1 button[disabled]:hover {
  background-color: #d3d3d3; 
}

@media (max-width: 600px) {
  .modal-content1 {
    width: 90%;
    padding: 1.5rem;
  }

  .modal-content1 h2 {
    font-size: 1.5rem;
  }

  .modal-content1 button {
    font-size: 1rem;
    padding: 0.8rem;
  }
  .modal-content1 .close-button {
    left: 9rem;}
}

@media (max-width: 400px) {
  .modal-content1 h2 {
    font-size: 1.3rem;
  }
}

.profile-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px 0px 20px;
  min-height: calc(100vh - 96px);
  position: relative;
  width: calc(100% - 200px);
  left: 200px;
  transition: width 0.3s ease, left 0.3s ease;
}
.sidebar-closed.profile-page {
  width: calc(100% - 60px);
  left: 60px;
}

.left-section,
.right-section {
  background: #fff;
  border-radius: 15px;
  padding: 30px;
  margin: 20px 10px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.left-section:hover,
.right-section:hover {
  transform: translateY(-10px);
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.2);
}

.profile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
}

.profile-image {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 5px solid rgb(22, 106, 234);
  margin-bottom: 15px;
  object-fit: cover;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.profile-header h2 {
  font-size: 28px;
  margin: 10px 0;
  color: #333;
}

.profile-header h3 {
  font-size: 18px;
  margin: 5px 0;
  color: #666;
  display: flex;
  align-items: center;
}

.details p {
  font-size: 16px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  color: #555;
}

.details p strong {
  margin-right: 10px;
}

.details p svg {
  margin-right: 10px;
}

.profile-header h3 svg {
  margin-right: 10px;
}

.profile-edit-button svg {
  margin-right: 8px;
}
.edit-buttons-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 15px;
}

.profile-edit-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(22, 106, 234);
  color: #fff;
  border: none;
  padding: 12px 25px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
.profile-save-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 12px 25px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
.profile-edit-button svg,
.profile-save-button svg {
  margin-right: 8px; /* Space between icon and text */
}

.profile-edit-button:hover {
  background-color: rgb(36, 122, 250);
  transform: translateY(-3px);
}
.profile-save-button:hover {
  transform: translateY(-3px);
  background-color: #1bc643;
}

.bio h3 {
  font-size: 22px;
  margin-bottom: 15px;
  color: #333;
  font-weight: bold;
}

.bio p {
  color: #555;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  border-radius: 10px;
}

.profile-textarea {
  width: 100%;
  padding: 15px;
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid #ddd;
  font-size: 16px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.bio textarea:focus {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
}

.login-info {
  margin-top: 30px;
  font-size: 18px;
}

.login-info p {
  margin: 15px 0;
  color: #333;
}

.image-upload-container {
  position: relative; /* Set to relative for absolute positioning of children */
  display: inline-block; /* Make it wraps around the image properly */
}

.image-upload-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  background: linear-gradient(135deg, #1c7ed6, #0072ff);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.file-buttons {
  display: flex;
  gap: 10px;
  position: absolute;
  left: -27px !important;
}
.profile-header h2 {
  font-size: 28px;
  margin: 10px 0;
  color: #333;
  transition: margin-bottom 0.3s;
}
.profile-save-button {
  padding: 10px 30px;
}

.profile-image {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 5px solid rgb(22, 106, 234);
  margin-bottom: 15px;
  object-fit: cover;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  position: relative;
}

/* Responsive Design */
@media (max-width: 768px) {
  .profile-page {
    padding: 20px 10px;
    margin-top: 10%;
  }
  .sidebar-closed.profile-page {
    width: 100%;
    left: 0px;
  }

  .left-section,
  .right-section {
    max-width: 100%;
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .profile-page {
    margin-top: 15%;
  }

  .profile-header h2 {
    font-size: 24px;
  }

  .profile-header h3 {
    font-size: 16px;
  }

  .profile-edit-button,
  .profile-save-button {
    font-size: 14px;
    padding: 10px 20px;
  }
}

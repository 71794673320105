.Buy-token-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 75px);
  background: linear-gradient(135deg, #013698, hsl(275, 97%, 36%), #8a2be2);
  color: white;
  position: relative;
  overflow: hidden;
  width: calc(100% - 200px);
  left: 200px;
  transition: width 0.3s ease, left 0.3s ease;
}
.sidebar-closed.Buy-token-section {
  width: calc(100% - 60px);
  left: 60px;
}

.Buy-token-link-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  max-width: 1300px;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.Buy-token-link-GIF {
  flex: 1;
  margin-right: 20px;
  max-width: 600px;
  width: 70%;
}

.Buy-token-link-content {
  flex: 2;
  text-align: left;
  padding: 20px;
}

.Buy-token-link-section h3 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 15px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
  animation: move-heading 3s infinite ease-in-out,
    text-glow 3s infinite ease-in-out;
}

.Buy-token-link-section p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 20px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
}

.Buy-token-button,
.Buy-token-demo-link {
  display: inline-block;
  padding: 12px 25px;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  background: linear-gradient(135deg, #013698, hsl(275, 97%, 36%), #8a2be2);
  color: white;
  cursor: pointer;
  text-decoration: none;
  margin: 10px 20px 10px 0;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.Buy-token-button:hover,
.Buy-token-demo-link:hover {
  transform: translateY(-5px) scale(1.05);
}

@media (max-width: 1024px) {
  .Buy-token-link-section {
    flex-direction: column;
    text-align: center;
    width: 90%;
    padding: 20px;
  }

  .Buy-token-link-GIF {
    margin: 10px auto;
  }

  .Buy-token-link-content {
    text-align: center;
    padding: 10px;
  }

  .Buy-token-link-section h3 {
    font-size: 2.5rem;
  }

  .Buy-token-link-section p {
    font-size: 1.3rem;
    padding: 0 30px;
  }
}

@media (max-width: 768px) {
  .sidebar-closed.Buy-token-section {
    width: 100%;
    left: 0px;
  }
  .Buy-token-link-section {
    padding: 15px;
  }

  .Buy-token-link-content h3 {
    font-size: 1.8rem;
  }

  .Buy-token-link-section p {
    font-size: 0.85rem;
  }

  .Buy-token-button,
  .Buy-token-demo-link {
    padding: 8px 15px;
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .Buy-token-link-section {
    padding: 10px;
    margin-top: 5%;
  }

  .Buy-token-link-GIF {
    max-width: 250px;
    margin: 0 auto;
  }

  .Buy-token-link-section h3 {
    font-size: 1.5rem;
  }

  .Buy-token-link-section p {
    font-size: 0.8rem;
    padding: 0 10px;
  }

  .Buy-token-button,
  .Buy-token-demo-link {
    padding: 6px 10px;
    font-size: 0.7rem;
  }
}
@media (max-width: 380px) {
  .Buy-token-link-section {
    margin-top: 25%;
  }
}

.footer {
  padding: 0px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-top: 1px solid #e4e4e4;
  bottom: 0;
  position: relative;
  width: calc(100% - 170px);
  left: 170px;
  transition: width 0.3s ease, left 0.3s ease;
}

.footer.sidebar-closed {
  /* width: calc(100% - 60px);
  left: 60px; */
  width: calc(100% - 0px);
  left: 0px;
}

a {
  text-decoration: none;
}

.footer-left {
  display: flex;
  align-items: center;
}

.footer-logo {
  height: 70px;
  transition: transform 0.3s ease;
}

.footer-logo:hover {
  transform: scale(1.05);
}

.footer-divider {
  border-left: 2px solid #ccc;
  height: 30px;
}

.footer-about {
  margin-left: 10px;
}

.footer-text {
  font-size: 18px;
  color: black;
  position: relative;
  transition: color 0.3s ease;
}

.footer-text::after {
  content: "";
  display: block;
  height: 2px;
  background: rgb(22, 106, 234);
  width: 100%;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.footer-text:hover::after {
  transform: scaleX(1);
}

.footer-center {
  display: flex;
  align-items: center;
}

.footer-center-text {
  font-size: 15px;
  color: #55585a;
  opacity: 0.9;
}

.footer-links {
  display: flex;
  align-items: center;
}

.footer-link {
  font-size: 15px;
  color: rgb(22, 106, 234);
  margin: 0 5px;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: rgb(36, 122, 250);
}

.footer-separator {
  color: #6b6969;
}

.footer-demo-link {
  color: rgb(22, 106, 234);
  border: none;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(135deg, #00c6ff, #0072ff) border-box;
  border: 2px solid transparent;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-decoration: none;
}

.footer-demo-link:hover {
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: #ffffff;
  border-color: #1c7ed6;
  transform: translateY(-2px);
}

/* Responsive Design */

@media (max-width: 1000px) {
  .footer {
    flex-direction: column;
    padding: 20px;
    text-align: center;
  }

  .footer-left,
  .footer-center,
  .footer-demo-link {
    margin-bottom: 20px;
  }

  .footer-left {
    flex-direction: column;
  }

  .footer-logo {
    height: 65px;
  }

  .footer-about {
    margin-left: 0;
  }

  .footer-divider {
    display: none;
  }

  .footer-center {
    flex-direction: column;
    gap: 10px;
  }

  .footer-center-text {
    font-size: 15px;
  }

  .footer-links {
    gap: 10px;
  }

  .footer-link {
    margin: 5px 0;
    font-size: 15px;
  }

  .footer-demo-link {
    font-size: 15px;
    padding: 8px 20px;
    width: 50%;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    padding: 20px;
    text-align: center;
  }

  .footer.sidebar-closed {
    width: 100%;
    left: 0px;
  }

  .footer-left,
  .footer-center,
  .footer-demo-link {
    margin-bottom: 20px;
  }

  .footer-left {
    flex-direction: column;
  }

  .footer-logo {
    height: 60px;
  }

  .footer-about {
    margin-left: 0;
  }

  .footer-divider {
    display: none;
  }

  .footer-center {
    flex-direction: column;
    gap: 10px;
  }

  .footer-center-text {
    font-size: 14px;
  }

  .footer-links {
    gap: 10px;
  }

  .footer-link {
    margin: 5px 0;
    font-size: 14px;
  }

  .footer-demo-link {
    font-size: 14px;
    padding: 8px 20px;
    width: 50%;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 15px;
  }

  .footer-logo {
    height: 50px;
  }

  .footer-text {
    font-size: 16px;
  }

  .footer-center-text,
  .footer-link {
    font-size: 13px;
  }

  .footer-demo-link {
    font-size: 14px;
    padding: 10px 20px;
  }
}

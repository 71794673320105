.dialog-box {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  margin: 50px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.dialog-box h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-weight: bold;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form label {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  color: #555;
}

.form input,
.form select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-top: 5px;
  width: 100%;
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}

.actions button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.actions button:hover {
  background-color: #0056b3;
}

.message {
  margin-top: 20px;
  color: green;
  font-weight: bold;
  text-align: center;
}

.modal-content_oracle {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.close-button_oracle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.close-button_oracle > button {
  margin-left: auto;
  margin-bottom: 20px;
}

/* for table// */
.table-container {
  overflow-x: auto;
  margin: 20px 0;
}
.table-container2 {
  width: 95%;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
  text-align: left;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.styled-table thead tr {
  background-color: #4caf50;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.styled-table tbody tr:nth-child(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:hover {
  background-color: #e1f5e1;
  cursor: pointer;
}

.styled-table tbody tr:last-child {
  border-bottom: 2px solid #4caf50;
}

.styled-table th {
  border-bottom: 2px solid #4caf50;
}

/* Close Button Style */
.close-btn {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 15px;
}

.close-btn:hover {
  background-color: #45a049;
}

.actions {
  text-align: center;
  margin-top: 20px;
}
